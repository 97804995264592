import React, { useState, useEffect } from "react";
import { db, storage, realTimeDb } from "../firebase";
import { ResponsiveEmbed, Modal } from "react-bootstrap";
import {
  Container,
  Card,
  Grid,
  CardMedia,
  CardContent,
  Button,
  CircularProgress,
  CardActions,
  Typography,
  CardActionArea,
  Collapse,
} from "@material-ui/core";
import CloudDownload from "@material-ui/icons/CloudDownload";
import Iframe from "react-iframe";
import CanvasJSReact from "../canvasjs.react";

function Materials() {
  const [mats, setMats] = useState([]);
  const [activeMaterial, setActiveMaterial] = useState([]);
  const [show, setShow] = useState(false);
  const [activeChart, setActiveChart] = useState();
  const [downloads, setDownloads] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const storageRef = storage.ref();
  const realTimeRef = realTimeDb.ref();
  var CanvasJS = CanvasJSReact.CanvasJS;
  var CanvasJSChart = CanvasJSReact.CanvasJSChart;
  function handleClick(mat) {
    setActiveChart(<CircularProgress className="offset-5 mt-5" />);
    setActiveMaterial(mat);
    handleShow();
    const activeRef = storageRef.child(mat.name.toLowerCase());
    setDownloads([]);

    activeRef.listAll().then((files) => {
      files.items.forEach((itemRef) => {
        itemRef.getDownloadURL().then((url) => {
          let newItem = (
            <Grid item>
              <Button
                variant="contained"
                className="bg-primary text-light"
                startIcon={<CloudDownload />}
                href={url}
                target="_blank"
              >
                {itemRef.name}
              </Button>
            </Grid>
          );
          setDownloads((downloads) => [...downloads, newItem]);
        });
      });
    });

    realTimeRef
      .child(mat.name)
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          var options = {
            animationEnabled: true,
            zoomEnabled: true,
            exportEnabled: true,
            theme: "light2",
            legend: {
              cursor: "pointer",
              verticalAlign: "top",
              horizontalAlign: "center",
              dockInsidePlotArea: true,
            },
            data: [
              {
                showInLegend: true,
                type: "line",
                name: "exp",
                dataPoints: data.exp,
              },
              {
                showInLegend: true,
                type: "line",
                name: "DFT",
                dataPoints: data.dft,
              },
            ],
            axisX: {
              title: "Energy (cm\u207B\u00B9)",
              minimum: 10,
              maximum: 1500,
            },
            axisY: {
              title: "Intensity (AU)",
            },
          };
          if (data.dftb) {
            var newData = {
              showInLegend: true,
              type: "line",
              name: "DFTB+",
              dataPoints: data.dftb,
            };
            options["data"].push(newData);
          }
          if (data.chimes) {
            var newData = {
              showInLegend: true,
              type: "line",
              name: "DFTB+/ChIMES",
              dataPoints: data.chimes,
            };
            options["data"].push(newData);
          }
          setActiveChart(<CanvasJSChart options={options}></CanvasJSChart>);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function add3dImg() {
    if (!document.getElementById("activeMaterialImg").src) {
      const altImg = document.getElementById(activeMaterial.id).src;
      const imageUrl = activeMaterial.structureUrl
        ? activeMaterial.structureUrl
        : altImg;
      var activeImg = document.getElementById("activeMaterialImg");
      if (activeImg) {
        document.getElementById("activeMaterialImg").src = imageUrl;
      }
    }
  }
  const fetchMats = async () => {
    const response = db.collection("materials");
    const data = await response.get();
    data.docs.forEach((item) => {
      setMats((mats) => [...mats, item.data()]);
    });
    console.log(mats.length);
  };
  useEffect(() => {
    fetchMats();
  }, []);
  function fetchImage(location, id) {
    var value;
    const image = storageRef
      .child(location)
      .getDownloadURL()
      .then(function (url) {
        value = url;
        var img = document.getElementById(id);
        if (img) {
          img.setAttribute("src", url);
        }
      });
    return value;
  }
  let cards;
  if (mats === null) {
    cards = <p>Loading Materials...</p>;
  } else if (mats.length === 0) {
    cards = (
      <Grid
        container
        direction="row"
        justify="center"
        sm={12}
        className=" pt-5"
      >
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  } else {
    console.log(mats.length);
    cards = mats.map((mat) => (
      <Grid item md={3} key={mat.id} id={"card" + mat.id} className="my-card">
        <Card className="h-100">
          <CardActionArea onClick={() => handleClick(mat)}>
            <CardMedia component="img" src="" id={mat.id} />
            <CardContent
              className="bg-secondary align-items-center"
              onLoad={fetchImage(
                mat.name.toLowerCase() + "/skeletalView.png",
                mat.id
              )}
            >
              <Typography gutterBottom variant="h5" component="h2">
                {mat.name}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                className="text-truncate"
              >
                {mat.longName}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    ));
  }
  const modal = (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="width-90"
      // onLoad={add3dImg}
    >
      <Modal.Header>
        <Modal.Title>
          {activeMaterial ? activeMaterial.name : "No Material Selected"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Grid container direction="row">
          <Grid item md={4} className="d-none d-sm-none d-md-none d-lg-block">
            <ResponsiveEmbed>
              <Iframe
                id="activeMaterialImg"
                src={activeMaterial.structureUrl}
              ></Iframe>
            </ResponsiveEmbed>
          </Grid>
          <Grid item md={8} className="d-none d-sm-none d-md-none d-lg-block">
            {activeChart}
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-evenly"
            spacing={3}
            className="my-3"
          >
            {downloads}
          </Grid>
        </Grid>
      </Modal.Body>
    </Modal>
  );

  return (
    <>
      <Container>
        {modal}
        <Grid container direction="row" justify="center" spacing={3}>
          {cards}
        </Grid>
      </Container>
    </>
  );
}

export default Materials;
