import { React, useState } from "react";
import {
  Grid,
  Button,
  Container,
  Drawer,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@material-ui/core";
import {
  Menu,
  Info,
  Group,
  CallSplit,
  People,
  AccountCircle,
  ArrowBack,
  Home,
} from "@material-ui/icons";
import logo from "../images/DCS Logo.png";
export default function App() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  return (
    <>
      <AppBar position="static" color="inherit" className="mb-5 bg-primary">
        <Toolbar>
          <IconButton
            edge="start"
            aria-label="menu"
            onClick={() => setShow(true)}
          >
            <Menu className="drawer-icon" />
          </IconButton>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={3}
            className="my-1"
          >
            <Grid item className="d-none d-md-block">
              <a href="/">
                <img src={logo} height="50px" />
              </a>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={show} onClose={handleClose}>
        <div role="presentation" className="bg-primary drawer-div">
          <List className="my-drawer text-light">
            <ListItem className="my-drawer-item" button onClick={handleClose}>
              <ListItemIcon>
                <ArrowBack className="drawer-icon"></ArrowBack>
              </ListItemIcon>
            </ListItem>
            <Divider />
            <ListItem className="my-drawer-item" button component="a" href="/">
              <ListItemIcon>
                <Home className="drawer-icon"></Home>
              </ListItemIcon>
              <ListItemText primary="Home"></ListItemText>
            </ListItem>
            <ListItem
              className="my-drawer-item"
              button
              component="a"
              href="/about"
            >
              <ListItemIcon>
                <Info className="drawer-icon"></Info>
              </ListItemIcon>
              <ListItemText primary="About"></ListItemText>
            </ListItem>
            <ListItem
              className="my-drawer-item"
              button
              component="a"
              target="_blank"
              href="https://dcs-flow.readthedocs.io/en/latest/index.html"
            >
              <ListItemIcon>
                <CallSplit className="drawer-icon"></CallSplit>
              </ListItemIcon>
              <ListItemText primary="DCS-Flow"></ListItemText>
            </ListItem>
            <ListItem
              className="my-drawer-item"
              button
              component="a"
              target="_blank"
              href="https://moule.ech.ucdavis.edu/"
            >
              <ListItemIcon>
                <People className="drawer-icon"></People>
              </ListItemIcon>
              <ListItemText primary="Moulė Lab"></ListItemText>
            </ListItem>
            <ListItem
              className="my-drawer-item"
              button
              component="a"
              href="/login"
            >
              <ListItemIcon>
                <AccountCircle className="drawer-icon"></AccountCircle>
              </ListItemIcon>
              <ListItemText primary="Log In"></ListItemText>
            </ListItem>
          </List>
        </div>
      </Drawer>
    </>
  );
}
