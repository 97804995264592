import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAirL4mKdCU7PKDHqIuwDVgnouLzFDHDvo",
  authDomain: "dcs-discover.firebaseapp.com",
  projectId: "dcs-discover",
  storageBucket: "dcs-discover.appspot.com",
  databaseURL: "https://dcs-discover-default-rtdb.firebaseio.com",
  messagingSenderId: "288041051738",
  appId: "1:288041051738:web:a31de8e271300b86c89a63",
  measurementId: "G-ZTTMKHJHSQ",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const storage = firebase.storage();
const realTimeDb = firebase.database();

export { realTimeDb, db, storage };
