import React, { useState, useEffect } from "react";
import Materials from "./components/materials";
import My_Navbar from "./components/navbar";
import MyFooter from "./components/MyFooter";
import About from "./components/About";
import Login from "./components/Login";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="App">
        <My_Navbar></My_Navbar>
        <Switch>
          <Route path="/" exact component={Materials} />
          <Route path="/about" component={About} />
          <Route path="/login" component={Login} />
        </Switch>
        <MyFooter></MyFooter>
      </div>
    </Router>
  );
}

export default App;
