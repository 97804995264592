import { React, useState } from "react";
import {
  Container,
  Typography,
  Fab,
  Tooltip,
  Dialog,
  DialogTitle,
  Button,
  Grid,
  Snackbar,
  IconButton,
} from "@material-ui/core";
import { FormatQuote, Close } from "@material-ui/icons";

export default function MyFooter() {
  const [open, setOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const copyText = () => {
    const textToCopy =
      "Dettmann, M.A.; Vong, D.; Cavalcante, L.S.R.; Magdaleno, C.; Masalkovaitė, K.; Harrelson, T.F.; Daemen, L.L.; Moulė, A.J. (2021) DCS Discover. https://dcs-discover.web.app/";
    navigator.clipboard.writeText(textToCopy);
    setOpen(false);
    setSnackOpen(true);
  };
  const style = {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
  };
  return (
    <>
      <Tooltip title="Cite" className="floating">
        <Fab
          color="primary"
          style={style}
          aria-label="add"
          onClick={() => setOpen(true)}
        >
          <FormatQuote />
        </Fab>
      </Tooltip>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackOpen}
        autoHideDuration={6000}
        onClose={() => setSnackOpen(false)}
        message="Text copied!"
      />
      <Container>
        <Dialog
          onClose={() => setOpen(false)}
          aria-labelledby="simple-dialog-title"
          open={open}
          maxWidth="lg"
        >
          <DialogTitle id="simple-dialog-title">Cite As</DialogTitle>
          <Typography className="p-4">
            Dettmann, M.A. et al. (2021) DCS Discover.{" "}
            <a href="https://dcs-discover.web.app/">
              https://dcs-discover.web.app/
            </a>
            <a href="https://zenodo.org/badge/latestdoi/339552091">
              <img src="https://zenodo.org/badge/339552091.svg" alt="DOI" />
            </a>
          </Typography>
          <Grid
            container
            direction="column"
            alignContent="center"
            alignItems="center"
            className="p-4"
          >
            <Grid item xs={1}>
              <Button variant="contained" color="primary" onClick={copyText}>
                Copy
              </Button>
            </Grid>
          </Grid>
        </Dialog>
        <footer class="container-fluid mt-5 pt-5 border-top">
          <p class="text-center">© {new Date().getFullYear()} Moulė Lab</p>
          <p class="text-center small">
            This work was supported by the Department of Energy, Basic Energy
            Sciences, Award DE-SC0010419, including salary for M.A.D., L.C.,
            D.V., and A.J.M. This research used resources of the National Energy
            Research Scientific Computing Center, a DOE Office of Science User
            Facility supported by the Office of Science of the U.S. Department
            of Energy under Contract No. DE-AC02-05CH11231. The INS spectrum was
            measured at the Spallation Neutron Source, a DOE Office of Science
            User Facility operatedby the Oak Ridge National Laboratory, partly
            supported by LLNL under Contract DE-AC52-07NA27344.
          </p>
        </footer>
      </Container>
    </>
  );
}
